

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  .footer {
    flex: 1;
    flex-shrink: 0;
    border-radius: 40px;
    background: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .row {
      flex: 1;
      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 10px;
      }
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 24px;
      .logo {
        width: 166px;
        height: 58px;
        flex-shrink: 0;
        margin-right: 32px;
      }
      p {
        color: #000;
        text-align: left;
        flex: 1;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 133.333% */
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        @media (max-width: 450px) {
          flex-direction: column;
        }
        button {
          display: flex;
          padding: 8px var(--Sizing-M, 16px);
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          background: #222;
          color: var(--white-black-white, #FFF);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
        }
      }
      .list {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;
        @media (max-width: 1024px) {
          gap: 20px;
        }
        justify-content: center;
        li {
          color: #444;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
      .icons {
        display: inline-flex;
        align-items: flex-start;
        gap: var(--Sizing-M, 16px);
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
